function Write(dataRows, fileName) {
  // Note: Requires that the objects in the datarows be flat- no nesting allowed!

  const headers = Object.keys(dataRows[0]).join(',')
  const lines = dataRows.map(row => Object.values(row).join(',')).join('\n')

  let csvText = "data:text/csv;charset=utf-8," + [headers, lines].join('\n')

  const encodedUri = encodeURI(csvText)
  const anchorElement = document.createElement("a")
  anchorElement.href = encodedUri
  anchorElement.download = `${fileName.replaceAll(/[/\\?%*:|"<> ]/g, '-')}.csv`

  document.body.appendChild(anchorElement)
  anchorElement.click()

  document.body.removeChild(anchorElement)
}

export default {
  Write,
}